.position--relative {
  position: relative;
}

.position--absolute {
  position: absolute;
}

.margin-top--5 {
  margin-top: 5px;
}

.margin-top--10 {
  margin-top: 10px;
}

.margin-top--15 {
  margin-top: 15px;
}

.margin-top--20 {
  margin-top: 20px;
}

.margin-top--25 {
  margin-top: 20px;
}

.margin-top--30 {
  margin-top: 30px;
}

.margin-top--35 {
  margin-top: 35px;
}

.margin-top--40 {
  margin-top: 40px;
}

.margin-top--45 {
  margin-top: 45px;
}

.margin-top--50 {
  margin-top: 50px;
}

.text--capitalize {
  text-transform: capitalize !important;
}

.text-align--right {
  text-align: right
}

.float--right {
  float: right
}