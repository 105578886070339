/* ---------------------------------------------------
    Login
----------------------------------------------------- */

.login_wrapper {
	right: 0;
	margin: 5% auto 0;
	max-width: 380px;
	position: relative;
	margin: 10% auto 0 !important;
}

.login_content {
	text-shadow: none !important;
	margin: 0 auto;
	padding: 70px 40px 30px;
	position: relative;
	text-align: center;
	background: #ffffff;
	border-radius: 10px;
	text-shadow: 0 1px 0 #fff;
	min-width: 280px;
	box-shadow: 0px 7px 41px 0px rgba(0, 0, 0, 0.1);
}

.login_content a:hover {
	text-decoration: none !important;
}

.login_content form input {
	box-shadow: none;
}

.login_content .logo {
	max-width: 100%;
}

.login_content .logo img {
	margin-bottom: 50px;
	width: 100%;
}

.elogin-logo {
	margin-bottom: 25px !important;
}

.reset-pass .heading {
	text-align: left !important;
	margin: 0 !important;

}

.reset-pass p {
	text-align: left !important;
	margin: 15px 0 30px;
}

/* ---------------------------------------------------
    Placeholder
----------------------------------------------------- */

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: rgba(0, 0, 0, .4) !important;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: rgba(0, 0, 0, .4) !important;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: rgba(0, 0, 0, .4) !important;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: rgba(0, 0, 0, .4) !important;
}


/* ---------------------------------------------------
    SWITCH
----------------------------------------------------- */

.switch {
	position: relative;
	display: inline-block;
	width: 43px;
	height: 24px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: 2px;
	bottom: 2px;
	background-color: white;
	-webkit-transition: .1s;
	transition: .1s;
}

input:checked + .slider {
	background-color: #66D1BA;
	box-shadow: none;
}

input:focus + .slider {
	box-shadow: 0 0 0px #66D1BA;
}

input:checked + .slider:before {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
}

/* Rounded sliders */

.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}







/* ---------------------------------------------------
    input [type = file]
----------------------------------------------------- */

input[type=file] {
	display: block !important;
	right: 1px;
	top: 1px;
	height: 34px;
	opacity: 0;
	width: 100%;
	background: none;
	position: absolute;
	overflow: hidden;
	z-index: 2;
}

.control-fileupload {
	display: block;
	border: 1px solid #d6d7d6;
	background: #FFF;
	border-radius: 4px;
	width: 100%;
	height: 36px;
	line-height: 36px;
	padding: 0px 10px 2px 10px;
	overflow: hidden;
	position: relative;
	z-index: 0;
	/* File upload button */
}

.control-fileupload:before,
.control-fileupload input,
.control-fileupload label {
	cursor: pointer !important;
}

.control-fileupload:before {
	/* inherit from boostrap btn styles */
	padding: 4px 12px;
	margin-bottom: 0;
	font-size: 14px;
	color: #333333;
	text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
	vertical-align: middle;
	cursor: pointer;
	background-color: #f5f5f5;
	background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
	background-repeat: repeat-x;
	border: 1px solid #cccccc;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	border-bottom-color: #b3b3b3;
	border-radius: 4px;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
	transition: color 0.2s ease;
	/* add more custom styles*/
	content: 'Browse';
	display: block;
	position: absolute;
	z-index: 1;
	top: 2px;
	right: 2px;
	line-height: 20px;
	text-align: center;
}

.control-fileupload:hover:before,
.control-fileupload:focus:before {
	background-color: #e6e6e6;
	color: #333333;
	text-decoration: none;
	background-position: 0 -15px;
	transition: background-position 0.2s ease-out;
}

.control-fileupload label {
	line-height: 38px;
	color: #999999;
	font-size: 12px;
	font-weight: normal;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	position: relative;
	z-index: 1;
	margin-right: 90px;
	margin-bottom: 0px;
	cursor: text;
}



/* ---------------------------------------------------
   iCheck plugin Flat skin, yellow
----------------------------------------------------- */

.icheckbox_flat-yellow,
.iradio {
	display: inline-block;
	*display: inline;
	vertical-align: middle;
	margin: 0;
	padding: 0;
	width: 20px;
	height: 20px;
	background: url(../../production/images/icheck.png) no-repeat;
	border: none;
	cursor: pointer;
}

.icheckbox_flat-yellow {
	background-position: 0 0;
}

.icheckbox_flat-yellow.checked {
	background-position: -22px 0;
}

.icheckbox_flat-yellow.disabled {
	background-position: -44px 0;
	cursor: default;
}

.icheckbox_flat-yellow.checked.disabled {
	background-position: -66px 0;
}

.iradio {
	background-position: -88px 0;
	transition: none;

}

.radio label {
	/*margin-right: 15px; */
	margin-right: 10px;
	display: flex;
	z-index: 99999;
	/* position: relative; */
}

.radio .iradio {
	padding-right: 22px;
	margin-right: 5px;
}

.iradio.checked {
	background-position: -110px 0;
	transition: none;

}

.iradio.disabled {
	background-position: -132px 0;
	cursor: default;
	transition: none;
}

.iradio.checked.disabled {
	background-position: -154px 0;
	transition: none;
}

/* HiDPI support */

@media (-o-min-device-pixel-ratio: 5/4),
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {
	.icheckbox_flat-yellow,
	.iradio {
		background-image: url(../../production/images/icheck@2x.png);
		-webkit-background-size: 176px 22px;
		background-size: 176px 22px;
	}
}



.grayout {
	opacity: 0.6;
}





/* ---------------------------------------------------
   Custom Select Dropdown
----------------------------------------------------- */

/* Custom Select Dropdown */

/*the container must be positioned relative:*/

.emr-patient-search{
	font-size: 12px;
	padding-top: 40px;
	padding-bottom: 2px;
	text-transform: none;
}

.emr-search-results{
	display: flex;
	padding-top: 15px;
  }

.custom-select {
	position: relative;

}

.custom-select select {
	display: none;
	/*hide original SELECT element:*/
}

.select-selected {
	background-color: #fff;
	border: 1px solid #ccc;
}

/*style the arrow inside the select element:*/

.select-selected:after {
	position: absolute;
	content: "";
	top: 15px;
	right: 12px;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-color: #4d4d4d transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/

.select-selected.select-arrow-active:after {
	border-color: transparent transparent #414661 transparent;
	top: 8px;
}

/*style the items (options), including the selected item:*/

.select-items div,
.select-selected {
	color: #4d4d4d;
	/* padding: 6px 10px; */
	/* requested by nick */
	padding: 5px 10px;
	cursor: pointer;
	user-select: none;
}

/*style items (options):*/

.select-items {
	position: absolute;
	background-color: #ececec;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 99;
	margin-bottom: 60px;
}

/*hide the items when the select box is closed:*/

.select-hide {
	display: none;
}

.select-items div:hover,
.same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
}

.pagination > li > a,
.pagination > li > span {
	color: #777;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	padding: 0;
	margin: 0;
	display: initial !important;
	box-sizing: none;
	border: none !important;
}

.pagination a {
	margin-right: 0;
}

.pagination > li > a:hover,
.pagination > li:hover > span {
	color: #777;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background: #65D1B9;
	border-color: #65D1B9;

}

.dataTables_wrapper .dataTables_paginate .paginate_button a:focus {
	outline: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:active,
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	background: none !important;
	border: none !important;
	background-color: none !important;

}
